<template>
	<div class="mb-5">
		<vue-dropzone
			ref="dropzone"
			:id="elemId"
			:options="dropzoneOptions"
			:destroyDropzone="false"
			:useCustomSlot="true"
			@vdropzone-sending="sendingFiles"
			@vdropzone-success="success"
			@vdropzone-removed-file="removed"
		>
			<div class="dropzone-custom-content">
				<h3 class="dropzone-custom-title">
					{{ title }}
				</h3>
				<p class="mt-2 text-body-2">{{ subtitle }}</p>
			</div>
		</vue-dropzone>
	</div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import config from '@/config'
import { v4 as uuidv4 } from 'uuid'

export default {
	name: 'Dropzone',
	props: {
		initFiles: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: 'Drag/Drop or click here to upload files',
		},
		subtitle: {
			type: String,
		},
		url: {
			type: String,
			default: config.api.baseURL + '/media',
		},
		maxFileSize: {
			type: Number,
			default: 5, //MB
		},
		maxFiles: {
			type: Number,
			default: 10,
		},
		acceptedFiles: {
			type: String,
			default: null,
		},
		purpose: {
			type: String,
			required: true,
		},
		MaxFilesExceeded: {
			type: String,
			default: 'No. of files exceeded',
		},
		errorMessage: {
			type: String,
			default: 'Some error occured',
		},
		altText: {
			type: String,
			default: null,
		},
	},
	components: {
		vueDropzone: vue2Dropzone,
	},
	data: function () {
		return {
			elemId: null,
			files: {},
			dropzoneOptions: {
				url: this.url,
				maxFilesize: this.maxFileSize,
				dictDefaultMessage: 'Upload Files Here',
				dictMaxFilesExceeded: this.MaxFilesExceeded,
				acceptedFiles: this.acceptedFiles,
				clickable: true,
				maxFiles: this.maxFiles,
				addRemoveLinks: true,
				error: this.error,
				headers: {
					'X-Auth-Token': this.$cookies.get('x-auth-token'),
				},
			},
		}
	},
	methods: {
		sendingFiles(file, xhr, formdata) {
			formdata.append('file', { file })
			formdata.append('purpose', this.purpose)
		},
		removed(file) {
			const key = file.id ? file.id : file.upload.uuid
			this.axios
				.delete(`${this.url}/${this.files[key].id}`)
				.then((response) => {
					if (response.data.success) {
						delete this.files[key]
					}
					this.$emit('changed', Object.values(this.files))
				})
				.catch(function (error) {
					this.$emit('errorOccured', error)
				})
		},
		success(file, response) {
			this.files[file.upload.uuid] = {
				id: response.data.id,
				publicURL: response.data.publicURL,
			}
			this.$emit('changed', Object.values(this.files))
		},
		error(file) {
			var message = this.errorMessage
			file.previewElement.classList.add('dz-error')
			var _ref = file.previewElement.querySelectorAll(
				'[data-dz-errormessage]'
			)
			var _results = []
			var _len = 0
			var _i = 0
			var node = []
			for (_i = 0, _len = _ref.length; _i < _len; _i++) {
				node = _ref[_i]
				_results.push((node.textContent = message))
			}
			return _results
		},
	},
	mounted() {
		this.initFiles.map((file) => {
			file.size = file.size ? file.size : 5
			this.$refs.dropzone.manuallyAddFile(file, file.publicURL)
			this.files[file.id] = {
				id: file.media,
				publicURL: file.publicURL,
			}
		})
	},

	created() {
		this.elemId = uuidv4()
	},
}
</script>

<style>
.dz-details {
	visibility: hidden;
}
.dz-remove {
	width: 85% !important;
	background: #af2323 !important;
	border-radius: 10px !important;
	font-weight: 800 !important;
}
.dz-error-message {
	top: 5px !important;
}
.dz-error-mark {
	background: red;
	width: 27% !important;
	border-radius: 50px;
	left: 35% !important;
}
.dz-success-mark {
	background: green;
	width: 27% !important;
	border-radius: 50px;
	left: 35% !important;
}
</style>
